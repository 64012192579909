<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col >
        <v-text-field dense label="Ordem" v-model="currentItem.ordem" outlined :rules="requiredField" type="Number"/>
      </v-col>
      <v-col >
        <v-autocomplete dense label="Tipo Tabela"  v-model="currentItem.tipo_tabela" :items="tipotabela" item-text="nome" item-value="id" outlined :rules="requiredField" :loading="isLoadingTipotabela"  disabled/>
      </v-col>
      <v-col v-if="this.currentItem.tipo_tabela.id !== 4  && this.currentItem.tipo_tabela.id !== 5">
       <v-select hide-details :items="pfbOptions" label="PFB ou PMC" item-text="label" item-value="value" v-model="currentItem.pfb" outlined dense clearable/>
      </v-col>
      <v-col v-if="this.currentItem.tipo_tabela.id !== 4  && this.currentItem.tipo_tabela.id !== 5">
       <v-select hide-details :items="statusOptions" label="Restrito Hospitalar" item-text="label" item-value="value" v-model="currentItem.restrito_hospitalar" clearable outlined dense />
      </v-col>
      <v-col v-if="this.currentItem.tipo_tabela.id !== 4">
          <v-text-field dense label="Redutor %" v-model="currentItem.desconto" outlined  type="Number" clearable/>
      </v-col>
      <v-col v-if="this.currentItem.tipo_tabela.id !== 4">
          <v-text-field dense label="Acrescimo %" v-model="currentItem.acrescimo" outlined  type="Number" clearable />
      </v-col>
    </v-row>
    
    <v-row dense>
        <v-col >
          <v-autocomplete dense label="Tabela" :item-text="tabelaText" v-model="currentItem.tabela" :items="tabela"  item-value="id" outlined :rules="requiredField" :loading="isLoadingTabela" return-object/>
        </v-col>
        <v-col >
          <v-autocomplete dense label="Tipo Guia" :item-text="tipoguiaText" v-model="currentItem.tipoguia" :items="tipoguia"  item-value="id" outlined :rules="requiredField" :loading="isLoadingTipoGuia" return-object/>
        </v-col>
        <v-col >
          <v-autocomplete dense label="Tipo Despesa" :item-text="despesaText" v-model="currentItem.despesa" :items="despesa"  item-value="id" outlined :rules="requiredField" :loading="isLoadingDespesa" return-object/>
        </v-col>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-col>
        <label>Status</label>
        <div class="d-flex">
          <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
            <v-btn :color="!currentItem.ativo ? 'error' : ''">
              Inativo
            </v-btn>
            <v-btn :color="currentItem.ativo ? 'green' : ''">
              Ativo
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-col>
      <v-col class="text-end">
        <v-btn  dense style="color: red; font-weight: bold;" @click="limpar" class="mr-5">Limpar </v-btn>
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "NovoMaterial",
  props: {
    operadora: Number,
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      requiredField: [e => (e !== null && e !== undefined && e !== '' ? true : 'Obrigatório')],
      isLoadingTabela: false,
      isLoadingTipoGuia: false,
      isLoadingTipotabela: false,
      isLoadingDespesa: false,
      despesa: [],
      tipotabela: [],
      tipoguia: [],
      tabela: [],
      statusOptions: [
        { label: "Sim", value: true },
        { label: "Não", value: false }
      ],
      pfbOptions: [
        { label: "PFB", value: true },
        { label: "PMC", value: false }
      ],
    })
  },
  components: { ConfirmButton },
  methods: {
    localOnSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation)  return;

      const fields = {
        id: this.currentItem.id,
        ordem: this.currentItem.ordem,
        tabela: this.currentItem.tabela ? this.currentItem.tabela.id : '',
        despesa: this.currentItem.despesa ? this.currentItem.despesa.id : '',
        tipoguia: this.currentItem.tipoguia ? this.currentItem.tipoguia.id : '',
        desconto: this.currentItem.desconto ? this.currentItem.desconto : null,
        acrescimo: this.currentItem.acrescimo ? this.currentItem.acrescimo : null,
        restrito_hospitalar: this.currentItem.restrito_hospitalar,
        pfb: this.currentItem.pfb,
        ativo: this.currentItem.ativo

      }
      console.log('Enviado => ', fields)
      this.onSubmit(fields)
    },
    async getTipoTabela() {
        this.isLoadingTipotabela = true
      try {
        const { data } = await api.get('operadoras/tipotabela/')
        this.tipotabela = data.filter(tabela => tabela.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingTipotabela = false
      }
    },
    async getTabela() {
        this.isLoadingTabela = true
      try {
        const { data } = await api.get('operadoras/tabelas/')
        this.tabela = data.filter(tabela => tabela.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingTabela = false
      }
    },
    async getTipoguia() {
        this.isLoadingTipoGuia = true
      try {
        const { data } = await api.get('operadoras/tipoguia/')
        this.tipoguia = data.filter(tipoguia => tipoguia.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingTipoGuia = false
      }
    },
    async getDespesa() {
        this.isLoadingDespesa = true
      try {
        const { data } = await api.get('operadoras/despesas/')
        this.despesa = data.filter(despesa => despesa.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingDespesa = false
      }
    },
    limpar(){
      this.currentItem.desconto = null
      this.currentItem.acrescimo = null
      this.currentItem.pfb = null
      this.currentItem.restrito_hospitalar = null
    }
  },
  computed:{
    tabelaText() {
      return (item) => {
          return `${item.tabela} - ${item.nome}`;
      }
    },
     tipoguiaText() {
      return (item) => {
            return `${item.tipoguia} - ${item.nome}`;
      }
    },
     despesaText() {
      return (item) => {
            return `${item.despesa} - ${item.nome}`;
      }
    },

  },
  mounted() {
    this.getTabela()
    this.getTipoguia()
    this.getTipoTabela()
    this.getDespesa()
   
    this.currentItem.ativo = Number(this.currentItem.ativo)
  }
}
</script>
