<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col >
        <v-text-field dense label="Ordem" v-model="currentItem.ordem" outlined :rules="requiredField" type="Number"/>
      </v-col>
      <v-col >
        <v-autocomplete dense label="Tipo Tabela"  v-model="currentItem.tipo_tabela" :items="tipotabela" item-text="nome" item-value="id" outlined :rules="requiredField" :loading="isLoadingTipotabela" return-object/>
      </v-col>
      <v-col v-if="mostrarRedutor && mostrarManipualdo">
       <v-select hide-details :items="pfbOptions" label="PFB ou PMC" item-text="label" item-value="value" v-model="currentItem.pfb" outlined dense :rules="requiredField" />
      </v-col>
      <v-col v-if="mostrarRedutor && mostrarManipualdo">
       <v-select hide-details :items="statusOptions" label="Restrito Hospitalar" item-text="label" item-value="value" v-model="currentItem.restrito_hospitalar" outlined dense :rules="requiredField" />
      </v-col>
      <v-col  v-if="mostrarRedutor">
          <v-text-field dense label="Redutor %" v-model="currentItem.desconto" outlined  type="Number" />
      </v-col>
      <v-col  v-if="mostrarRedutor">
          <v-text-field dense label="Acrescimo %" v-model="currentItem.acrescimo" outlined  type="Number"/>
      </v-col>
    </v-row>
    
    <v-row dense>
        <v-col >
          <v-autocomplete dense label="Tabela" :item-text="tabelaText" v-model="currentItem.tabela" :items="tabela"  item-value="id" outlined :rules="requiredField" :loading="isLoadingTabela"/>
        </v-col>
        <v-col >
          <v-autocomplete dense label="Tipo Guia" :item-text="tipoguiaText" v-model="currentItem.tipoguia" :items="tipoguia"  item-value="id" outlined :rules="requiredField" :loading="isLoadingTipoGuia"/>
        </v-col>
        <v-col >
          <v-autocomplete dense label="Tipo Despesa" :item-text="despesaText" v-model="currentItem.despesa" :items="despesa"  item-value="id" outlined :rules="requiredField" :loading="isLoadingDespesa"/>
        </v-col>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <v-btn  dense style="color: red; font-weight: bold;" @click="limpar" class="mr-5">Limpar </v-btn>
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "NovoMaterial",
  props: {
    operadora: Number,
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      requiredField: [e => (e !== null && e !== undefined && e !== '' ? true : 'Obrigatório')],
      isLoadingTabela: false,
      isLoadingTipoGuia: false,
      isLoadingTipotabela: false,
      isLoadingDespesa: false,
      despesa: [],
      tipotabela: [],
      tipoguia: [],
      tabela: [],
      statusOptions: [
        { label: "Sim", value: true },
        { label: "Não", value: false }
      ],
      pfbOptions: [
        { label: "PFB", value: true },
        { label: "PMC", value: false }
      ],
    })
  },
  components: { ConfirmButton },
  methods: {
    localOnSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation)  return;

      const fields = {
        ...this.currentItem,
        operadora: this.operadora,
        tipo_tabela: this.currentItem.tipo_tabela.id,
        pfb: this.currentItem.pfb || true,
        restrito_hospitalar: this.currentItem.restrito_hospitalar || false
      }
      // console.log('Enviado => ', fields)
      this.onSubmit(fields)
    },
    async getTipoTabela() {
        this.isLoadingTipotabela = true
      try {
        const { data } = await api.get('operadoras/tipotabela/')
        this.tipotabela = data.filter(tabela => tabela.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingTipotabela = false
      }
    },
    async getTabela() {
        this.isLoadingTabela = true
      try {
        const { data } = await api.get('operadoras/tabelas/')
        this.tabela = data.filter(tabela => tabela.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingTabela = false
      }
    },
    async getTipoguia() {
        this.isLoadingTipoGuia = true
      try {
        const { data } = await api.get('operadoras/tipoguia/')
        this.tipoguia = data.filter(tipoguia => tipoguia.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingTipoGuia = false
      }
    },
    async getDespesa() {
        this.isLoadingDespesa = true
      try {
        const { data } = await api.get('operadoras/despesas/')
        this.despesa = data.filter(despesa => despesa.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingDespesa = false
      }
    },
    limpar(){
      this.currentItem.ordem = null
      this.currentItem.tipotabela = null
      this.currentItem.tabela = null
      this.currentItem.tipoguia = null
      this.currentItem.despesa = null
      this.currentItem.desconto = null
      this.currentItem.acrescimo = null
    }
  },
  computed:{
    tabelaText() {
      return (item) => {
          return `${item.tabela} - ${item.nome}`;
      }
    },
     tipoguiaText() {
      return (item) => {
            return `${item.tipoguia} - ${item.nome}`;
      }
    },
     despesaText() {
      return (item) => {
            return `${item.despesa} - ${item.nome}`;
      }
    },
    mostrarRedutor() {
      return !this.currentItem.tipo_tabela || this.currentItem.tipo_tabela.id !== 4;
    },
    mostrarManipualdo() {
      return !this.currentItem.tipo_tabela || this.currentItem.tipo_tabela.id !== 5;
    },

  },
  mounted() {
    this.getTabela()
    this.getTipoguia()
    this.getTipoTabela()
    this.getDespesa()
    this.currentItem.pfb = true
    this.currentItem.restrito_hospitalar = false

  }
}
</script>
