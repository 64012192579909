<template>
  <div>
    <v-data-table :loading="isloadingTabelas" :headers="headers" :items="tabela"  :server-items-length="-1" class="mb-4" :search="search">
      <template v-slot:top>
        <v-row class="mt-2">
          <v-col cols="12">
            <v-row>
              <v-col cols="5">
                <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify"
                  clearable />
              </v-col>
              <v-col class="text-end">
                <v-btn class="mr-4 elevation-0" text @click="atualziar">
                  <v-icon left>
                    mdi-reload
                  </v-icon>
                  atualizar
                </v-btn>
               
                <v-btn color="success" class="elevation-0" @click="dialog.create = true">
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Novo
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      
      <template v-slot:[`item.tipo_tabela`]="{ item }">
        {{item.tipo_tabela.nome}}
      </template>
      <template v-slot:[`item.restrito_hospitalar`]="{ item }">
        {{item.restrito_hospitalar ? 'Sim': ''}}
      </template>
      <template v-slot:[`item.manipulado`]="{ item }">
        {{item.manipulado ? 'Sim': ''}}
      </template>
      <template v-slot:[`item.pfb`]="{ item }">
        {{item.pfb === true ? 'PFB': item.pfb === false ? 'PMC': ''}}
      </template>
      <template v-slot:[`item.tabela`]="{ item }">
        {{item.tabela.tabela}} - {{item.tabela.nome}}
      </template>
      <template v-slot:[`item.despesa`]="{ item }">
        {{item.despesa.despesa}} - {{item.despesa.nome}}
      </template>
      <template v-slot:[`item.tipoguia`]="{ item }">
        {{item.tipoguia.tipoguia}} - {{item.tipoguia.nome}}
      </template>
      <template v-slot:[`item.desconto`]="{ item }">
        {{item.desconto ? `${item.desconto} %` : ''}}
      </template>
      <template v-slot:[`item.acrescimo`]="{ item }">
        {{item.acrescimo ? `${item.acrescimo} %` : ''}}
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip v-if="item.ativo === true" color="blue" class="white--text">ATIVA</v-chip>
        <v-chip v-else>INATIVA</v-chip>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar</span>
            </v-tooltip>
        </template>
    </v-data-table>
    <!-- Nova Tabela  -->
    <v-dialog v-if="dialog.create" v-model="dialog.create" max-width="1100">
      <v-card>
        <v-card-title class="blod_color">
          Nova Regra 
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <TabelaMatMedNovo :loading="isLoading('post:procedures')" :onSubmit="postProcedures" :currentItem="newItem" :operadora="currentItem"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Editar -->
    <v-dialog v-if="dialog.update" v-model="dialog.update" max-width="1100">
      <v-card>
        <v-card-title class="blod_color">
          Editar Regra
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <TabelaMatMedEditar :loading="isLoading('put:procedures')" :onSubmit="putProcedures" :currentItem="selectedRow" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import TabelaMatMedNovo from './TabelaMatMedNovo.vue';
import TabelaMatMedEditar from './TabelaMatMedEditar.vue';
import './../../../../assets/css/styles.css';

const { withCRUDUtils } = UtilsFunc

export default {
  name: "ProceduresTable",
  props: {
    currentItem: Number,
    onSubmit: Function
  },
  data: () => withCRUDUtils({
    newItem: { ativo:1 },
    isloadingTabelas: false,
    tabela: [],
    headers: [
      { text: "Editar", align: "center", value: "edit", width: 50, sortable: false },
      { text: "ID", align: "center", value: "id" },
      { text: "Ordem", align: "center", value: "ordem" },
      { text: "Tipo de Tabela", align: "center", value: "tipo_tabela" },
      { text: "PBF ou PMC", align: "center", value: "pfb" },
      { text: "Restrito Hospitalar", align: "center", value: "restrito_hospitalar" },
      { text: "manipulado", align: "center", value: "manipulado" },
      { text: "Cód. Tabela", align: "center", value: "tabela" },
      { text: "Cód. Tipo Guia", align: "center", value: "tipoguia" },
      { text: "Cód. Despesa", align: "center", value: "despesa" },
      { text: "Redutor", align: "center", value: "desconto" },
      { text: "Acrescimo", align: "center", value: "acrescimo" },
      { text: "Status", align: "center", value: "ativo" },
    ],
  }),
  methods: { 
     async getTabelaMatMed() {
      this.isloadingTabelas = true
      try {
        const { data } = await api.get(`/operadoras/tabela_precificacao/`)
        this.tabela = data.filter(item => item.operadora.id === this.currentItem)
        console.log(this.tabela)
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isloadingTabelas = false
      }
    },
    atualziar() {
      this.getTabelaMatMed()
    },
    
    async postProcedures(fields) {
      const LOADING_NAME = 'post:procedures'
      this.setLoading(LOADING_NAME)
      const fieldsWithOperator = {
        ...fields,
      }
      try {
        await api.post('/operadoras/tabela_precificacao_criar/', fieldsWithOperator)
        await this.getTabelaMatMed()
        this.$toast.success('Regra inserida com sucesso!')
        this.dialog.create = false
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async putProcedures(fields) {
      const LOADING_NAME = 'put:procedures'
      this.setLoading(LOADING_NAME)

      try {
        this.isSubmitting = true;
        console.log('Put Matmed -> ',fields)
        await api.put(`/operadoras/tabela_precificacao_editar/${fields.id}/`, fields)
        await this.getTabelaMatMed()
        this.$toast.success('Regra atualizada com sucesso!')
        this.dialog.update = false
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.isSubmitting = false;
      }
    },
  },
  mounted() {
    this.getTabelaMatMed();
  },
  components: { TabelaMatMedNovo, TabelaMatMedEditar },
  computed: {
    tipotabelaText() {
      return (item) => {
          return `${item.tabela} - ${item.nome} `;
      }
    },
  },
}
</script>

<style scoped>

</style>
